<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="7" md="7">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :qCreate="Qcreate"
          :qUpdate="Qupdate"
          :PageResponse="'attributes'"
          :AllResponse="'allattributs'"
          :title="'Liste des Attributs '"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Attributs'"
          :sheetname="'Attribut'"
          :key="klist"
          @open="OpenAttributForm"
          @rowselect="AttChange"
          :Add="editer"
          :Update="editer"
          :del="editer"
          :showedit="false"
          :del_disable="
            attribut.countvalues > 0 ||
            attribut.counttemplate > 0 ||
            attribut.countproduct > 0
          "
          :laoding="loading"
        >
        </listitemspage>
      </v-col>
      <v-col
        cols="12"
        sm="5"
        md="5"
        v-if="attribut.type_attr == 'variante' && attribut.display == 'Liste'"
      >
        <listitems
          :list="values_list"
          :qDelete="Qdetaildelete"
          :qCreate="Qdetailcreate"
          :qUpdate="Qdetailupdate"
          :Get_suf="'AttributValue'"
          :title="detailtitle"
          :headers="values_headers"
          :key="vl"
          :showstd="true"
          :master="true"
          :add="editer"
          :add_disable="attribut.id != null"
          :del="editer"
          :Update="editer && (value.countproducts == 0 || !value.countproducts)"
          :del_disable="value.countproducts > 0"
          :filename="'Valeurs'"
          :sheetname="'Valeur'"
          @rowselect="ValueChange"
        >
        </listitems>
      </v-col>
    </v-row>
    <attributform
      :item="attribut"
      :showForm="!isAttributClose"
      :readonly="!editer"
      :val_list="values_list"
      :units="units"
      :attributs_list="attributs_list"
      :key="kaf"
      @close="closeAttributForm"
    >
    </attributform>

    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-container>
</template>

<script>
import CREATE_ATTRIBUT from "../graphql/Template/CREATE_ATTRIBUT.gql";
import UPDATE_ATTRIBUT from "../graphql/Template/UPDATE_ATTRIBUT.gql";
import DELETE_ATTRIBUT from "../graphql/Template/DELETE_ATTRIBUT.gql";
import DELETE_ATTRIBUT_VALUE from "../graphql/Template/DELETE_ATTRIBUT_VALUE.gql";
import CREATE_ATTRIBUT_VALUE from "../graphql/Template/CREATE_ATTRIBUT_VALUE.gql";
import UPDATE_ATTRIBUT_VALUE from "../graphql/Template/UPDATE_ATTRIBUT_VALUE.gql";
import ALLATTRIBUTS from "../graphql/Template/ALLATTRIBUTS.gql";
//import ALLATTRIBUTS_SIMPLE from "../graphql/Template/ALLATTRIBUTS_SIMPLE.gql";
import ATTRIBUTES from "../graphql/Template/ATTRIBUTES.gql";
import UNITS from "../graphql/Structure/UNITS.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    listitemspage: () => import("../components/ListItemsPages.vue"),
    attributform: () => import("../components/AttributForm.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },

  data: () => ({
    loading: false,
    cf: 0,
    isClosed: true,
    open: [],
    opened: [],
    opened_save: [],
    active: [],
    active_save: [],
    editer: false,
    title: "",
    isAttributClose: true,
    list: [],
    klist: 400,
    kaf: 500,
    editeditem: {},
    selitem: {},
    listheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Ref.",
        value: "ref",
        enum: "REF",
        selected: true,
      },
      {
        text: "Libelle",
        align: "start",
        value: "label",
        enum: "LABEL",
        selected: true,
      },
    ],
    vl: 200,
    fl: 300,
    attribut: {},
    value: {},
    isAttClosed: true,
    isValueClosed: true,
    headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },

      {
        text: "Date creation",
        value: "created_at",
        slot: "date",
        enum: "CREATED_AT",
        selected: true,
      },

      {
        text: "Nom",
        value: "name",
        slot: "href",
        enum: "NAME",
        selected: true,
      },
      {
        text: "Caption",
        value: "caption",
        enum: "CAPTION",
        selected: true,
      },

      {
        text: "Type",
        value: "val_type",
        enum: "VAL_TYPE",
        selected: true,
      },
      {
        text: "Categorie",
        value: "type_attr",
        enum: "TYPE_ATTR",
        selected: true,
      },
      {
        text: "Affichage",
        value: "display",
        enum: "NAME",
        selected: false,
        sortable: false,
      },
      {
        text: "Cle",
        value: "iskey",
        slot: "checkbox",
        selected: false,
        sortable: false,
      },
      {
        text: "Largeur(%)",
        value: "width",
        enum: "WIDTH",
        selected: false,
      },
      {
        text: "Align.",
        value: "align",
        enum: "ALIGN",
        selected: false,
      },
      {
        text: "Nombre de Valeurs",
        value: "countvalues",
        align: "center",
        slot: "number",
        enum: "COUNTVALUES",
        selected: true,
        dec: 0,
      },
      {
        text: "Nombre de Modeles",
        value: "counttemplate",
        slot: "cur",
        align: "center",
        selected: true,
        enum: "COUNTTEMPLATE",
        dec: 0,
      },
      {
        text: "Nombre de Produits",
        value: "countproduct",
        align: "center",
        slot: "cur",
        selected: true,

        dec: 0,
      },
      {
        text: "Inclu Vente",
        value: "incl_sell",
        selected: false,
        sortable: false,
      },
      {
        text: "Inclu Achat",
        value: "incl_buy",
        selected: false,
        sortable: false,
      },
      {
        text: "Inclu Fabrication",
        value: "incl_manuf",
        selected: false,
        sortable: false,
      },
      {
        text: "Valeur par default",
        value: "default",
        selected: false,
        sortable: false,
      },
      {
        text: "Heritage",
        value: "hyritage",
        slot: "checkbox",
        selected: false,
        sortable: false,
      },
      {
        text: "Contrôle",
        value: "control",
        slot: "checkbox",
        selected: false,
        sortable: false,
      },
      {
        text: "Unité",
        value: "unit",
        selected: false,
        enum: "UNIT",
      },
    ],

    values_headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },
      {
        text: "Reference",
        value: "ref",
        edit: true,
        slot: "href",
        type: "text",
        rules: [(v) => !!v || "Reference obligatoire"],
        sm: 6,
        md: 3,
        selected: true,
      },
      {
        text: "Valeur",
        value: "name",
        edit: true,
        type: "text",
        type_base: "string",
        sm: 6,
        md: 6,
        selected: true,
      },
      {
        text: "Couleur",
        value: "html_color",
        edit: true,
        type: "color",
        sm: 3,
        md: 3,
        selected: true,
      },
      {
        text: "attribute_id",
        value: "attribute_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
      {
        text: "Nb Produits",
        value: "countproducts",
        enum: "COUNTPRODUCTS",
        slot: "cur",
        align: "center",
        selected: true,
        dec: 0,
      },
    ],
    v_list: [],
    units: [],
    attributs_list: [],
  }),
  watch: {},
  computed: {
    values_list() {
      return this.v_list ? this.v_list : [];
    },
    Qselect() {
      return ATTRIBUTES;
    },
    Qcreate() {
      return CREATE_ATTRIBUT;
    },
    Qupdate() {
      return UPDATE_ATTRIBUT;
    },
    Qselectall() {
      return ALLATTRIBUTS;
    },
    Qdelete() {
      return DELETE_ATTRIBUT;
    },
    Qdetaildelete() {
      return DELETE_ATTRIBUT_VALUE;
    },
    Qdetailcreate() {
      return CREATE_ATTRIBUT_VALUE;
    },
    Qdetailupdate() {
      return UPDATE_ATTRIBUT_VALUE;
    },
    detailtitle() {
      let n = this.attribut.name;
      return n ? "Liste " + n : "";
    },
  },
  created() {},

  async mounted() {
    this.editer =
      this.$store.state.auth.includes("02019") || this.$store.state.isadmin;
    let r = await this.requette(UNITS);
    if (r) this.units = r.units;
    // r = await this.requette(ALLATTRIBUTS_SIMPLE, { TypeScope: 0 });
    // if (r) this.attributs_list = r.allattributes;
  },

  methods: {
    async requette(query, v) {
      this.loading = true;
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    closeAttributForm() {
      this.isAttributClose = true;
    },

    AttChange(item) {
      this.attribut = item;
      this.selitem = item;
      this.v_list = this.attribut.id > 0 ? this.attribut.attributvalues : [];
      this.values_headers[2].type = this.attribut.val_type;
      this.values_headers[4].default = this.attribut.id;
      this.vl++;
    },
    OpenAttributForm(item) {
      this.attribut = item;
      this.selitem = item;
      this.isAttributClose = false;
      this.kaf++;
    },
    ValueChange(item) {
      this.value = item;
    },
  },
};
</script>

<style></style>
